export const useProfileStore = defineStore("profile", () => {
  const supabase = useSupabaseClient();
  const user = useSupabaseUser();
  const username = ref("");
  const displayName = ref("");
  const firstName = ref("");
  const lastName = ref("");
  const avatarUrl = ref("");
  const loadingProfile = ref(false);
  const loaded = ref(false);
  const paymentActive = ref(false);
  const areSettingsSet = computed(() => !!avatarUrl.value || !!displayName.value);

  // TODO: Dont load this data if the user is not logged in
  // Add column to the artist_profile table indicating if the settings were set once at least
  async function fetchProfile() {
    // Fetch the profile data from the database
    try {
      const { data, error } = await supabase
        .from("artist_profile")
        .select("username, display_name, first_name, last_name, avatar_url, payment_active")
        .eq("id", user.value?.id)
        .limit(1)
        .single();

      if (error) {
        throw error;
      }

      username.value = data.username;
      displayName.value = data.display_name;
      firstName.value = data.first_name;
      lastName.value = data.last_name;
      avatarUrl.value = data.avatar_url;
      paymentActive.value = data.payment_active;
      loaded.value = true;

    } catch (error) {
      useNuxtApp().$toast.error("Ocurrio un error al cargar el perfil");
    } finally {
      loadingProfile.value = false;
    }
  }

  return {
    username,
    displayName,
    firstName,
    lastName,
    fetchProfile,
    loaded,
    loadingProfile,
    avatarUrl,
    areSettingsSet,
    paymentActive,
  };
});
